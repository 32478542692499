<template>
  <div id="user-view">
    <v-row>
      <v-col v-if="jobCandidateData.id" cols="12" md="5" lg="4">
        <bread-crumbs
          :context="{
            jobName: jobCandidateData.offerName,
            jobId: jobCandidateData.offerId,
            candidateName: `${jobCandidateData.candidate.user.firstName} ${jobCandidateData.candidate.user.lastName}`,
          }"
        />
        <candidate-bio-panel :loading="loading" @refetchCandidate="fetchCandidateJobDetailsData" />
      </v-col>

      <v-col cols="12" md="7" lg="8">
        <v-tabs v-model="selectedTag" show-arrows class="user-tabs">
          <v-tab v-for="tab in tabs" :key="tab.icon">
            <v-icon size="20" class="me-3">
              {{ tab.icon }}
            </v-icon>
            <span>{{ $t(tab.title) }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          id="user-tabs-content"
          :key="jobCandidateData.id"
          v-model="selectedTag"
          class="mt-5 pa-3"
          touchless
        >
          <v-tab-item>
            <candidate-tab-files v-if="jobCandidateData.id" :key="componentTabKey" />
          </v-tab-item>
          <v-tab-item>
            <candidate-tab-reminders v-if="selectedTag === 1" @refetch-data="fetchCandidateJobDetailsData" />
          </v-tab-item>
          <v-tab-item>
            <candidate-tab-answers :key="componentTabKey" />
          </v-tab-item>
          <v-tab-item>
            <candidate-tab-custom-fields :key="componentTabKey" @refetchCandidate="fetchCandidateJobDetailsData" />
          </v-tab-item>
          <v-tab-item>
            <candidate-tab-consents :key="componentTabKey" />
          </v-tab-item>
          <v-tab-item>
            <candidate-tab-internal-notes v-if="jobCandidateData.id && !loading" :key="componentTabKey" />
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ref, onMounted, onUnmounted, getCurrentInstance, watch } from '@vue/composition-api'
import { useActions, useMutations, useState } from 'vuex-composition-helpers'
import {
  mdiAccountCancelOutline,
  mdiAccountOutline,
  mdiAlphaEBoxOutline,
  mdiCalendar,
  mdiDrag,
  mdiChevronLeft,
  mdiChevronRight,
  mdiBellOutline,
} from '@mdi/js'
import { subject } from '@casl/ability'
import { useRouter } from '@core/utils'
import router from '@/router'
import { PRIVATE, ACTIONS } from '@/plugins/acl/const'

import CandidateBioPanel from './candidate-bio-panel/CandidateBioPanel.vue'
import CandidateTabAnswers from './candidate-tab-answers/CandidateTabAnswers.vue'
import CandidateTabConsents from './candidate-tab-consents/CandidateTabConsents.vue'
import CandidateTabFiles from './candidate-tab-files/CandidateTabFiles.vue'
import CandidateTabInternalNotes from './candidate-tab-internal-notes/CandidateTabInternalNotes.vue'
import CandidateTabCustomFields from './candidate-tab-custom-fields/CandidateTabCustomFields.vue'
import BreadCrumbs from '@/components/files-table/BreadCrumbs.vue'
import CandidateTabReminders from '@/views/apps/ats/jobs/candidate-view/candidate-tab-reminders/CandidateTabReminders.vue'

export default {
  components: {
    CandidateBioPanel,
    CandidateTabAnswers,
    CandidateTabConsents,
    CandidateTabFiles,
    CandidateTabInternalNotes,
    CandidateTabCustomFields,
    BreadCrumbs,
    CandidateTabReminders,
  },
  setup() {
    const { getJobCandidateDetails } = useActions('jobs', ['getJobCandidateDetails'])
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])
    const { SET_JOB_CANDIDATE_DATA } = useMutations('jobs', ['SET_JOB_CANDIDATE_DATA'])

    const tabs = [
      { icon: mdiAlphaEBoxOutline, title: 'files' },
      { icon: mdiBellOutline, title: 'reminders' },
      { icon: mdiAccountCancelOutline, title: 'answers' },
      { icon: mdiDrag, title: 'customFields' },
      { icon: mdiAccountOutline, title: 'consents' },
      { icon: mdiCalendar, title: 'internalNotes' },
    ]

    const selectedTag = ref(null)

    const loading = ref(false)

    const componentTabKey = ref(0)
    const forceRerenderTab = () => {
      componentTabKey.value += 1
    }

    const fetchCandidateJobDetailsData = async () => {
      loading.value = true
      await getJobCandidateDetails({
        jobId: router.currentRoute.params.jobId,
        candidateId: router.currentRoute.params.candidateId,
      })
      loading.value = false
      forceRerenderTab()
    }

    const vm = getCurrentInstance().proxy

    const { route } = useRouter()

    watch(
      () => route.value.params.candidateId,
      async () => {
        await fetchCandidateJobDetailsData()
        if (!vm.$can(ACTIONS.READ, jobCandidateData.value)) vm.$router.push({ name: 'misc-not-authorized' })
      },
    )

    onMounted(async () => {
      await fetchCandidateJobDetailsData()
      if (!vm.$can(ACTIONS.READ, jobCandidateData.value)) vm.$router.push({ name: 'misc-not-authorized' })
    })

    onUnmounted(() => {
      SET_JOB_CANDIDATE_DATA(subject(PRIVATE.ATS.JOB_CANDIDATE, {}))
    })

    const moveToPreviousPage = async () => {
      await router.push({ name: 'apps-ats-job-view', params: { id: jobCandidateData.value.offerId } })
    }

    return {
      fetchCandidateJobDetailsData,
      moveToPreviousPage,

      loading,
      tabs,
      selectedTag,
      jobCandidateData,
      componentTabKey,

      icons: {
        mdiChevronLeft,
        mdiChevronRight,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
