<template>
  <reminder-table
    resource-name="candidateOfferDetail"
    :resource-id="jobCandidateData.id"
    @refetch-data="emit('refetch-data')"
  />
</template>

<script>
import { useState } from 'vuex-composition-helpers'
import ReminderTable from '@/components/reminders-table/ReminderTable.vue'

export default {
  components: { ReminderTable },
  setup(props, { emit }) {
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    return {
      emit,
      jobCandidateData,
    }
  },
}
</script>
