<template>
  <div class="user-tab-security">
    <!-- change password -->
    <v-card class="mb-7">
      <v-card-title> {{ $t('customFields') }} </v-card-title>

      <v-card-text>
        <v-row>
          <v-col
            v-for="(customField, index) in localCustomFields"
            :key="`CustomField-${customField.id}-${index}`"
            cols="12"
          >
            <v-text-field
              v-model="customField.field"
              outlined
              dense
              :label="customField.name"
              maxlength="100"
              hide-details
              :readonly="!$can(ACTIONS.UPDATE, jobCandidateData)"
            ></v-text-field>
          </v-col>
          <v-col v-if="$can(ACTIONS.UPDATE, jobCandidateData)" cols="12">
            <v-btn
              color="primary"
              class="me-3"
              :loading="loading"
              :disabled="!areCustomFieldsChanged"
              @click="handleCustomFieldsSubmit"
            >
              {{ $t('save') }}
            </v-btn>
            <v-btn outlined color="secondary" :disabled="!areCustomFieldsChanged" @click="restoreCustomFields">
              {{ $t('discard') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { ref, computed } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'
import _ from 'lodash'
import { ACTIONS } from '@/plugins/acl/const'

export default {
  setup(props, { emit }) {
    const { updateCandidateInOffer } = useActions('jobs', ['updateCandidateInOffer'])
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    const loading = ref(false)

    const localCustomFields = ref({})
    localCustomFields.value = _.cloneDeep(jobCandidateData.value.customFields)

    const handleCustomFieldsSubmit = async () => {
      loading.value = true
      const { success } = await updateCandidateInOffer({
        candidateDetailId: jobCandidateData.value.id,
        payload: {
          customFields: localCustomFields.value,
        },
      })

      loading.value = false

      if (success) {
        emit('refetchCandidate')
      }
    }

    const areCustomFieldsChanged = computed(() => {
      return (
        !_.isEmpty(_.differenceWith(localCustomFields.value, jobCandidateData.value.customFields, _.isEqual)) ||
        !_.isEmpty(_.differenceWith(jobCandidateData.value.customFields, localCustomFields.value, _.isEqual))
      )
    })

    const restoreCustomFields = () => {
      localCustomFields.value = _.cloneDeep(jobCandidateData.value.customFields)
    }

    return {
      handleCustomFieldsSubmit,
      restoreCustomFields,

      areCustomFieldsChanged,

      localCustomFields,
      loading,
      jobCandidateData,

      ACTIONS,
    }
  },
}
</script>
