<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-text>
        <v-row>
          <v-subheader v-t="'consent'" />
          <v-col cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-text>
                <div>{{ jobCandidateData.companyJobGdpr.consent }}</div>
                <v-divider class="mt-1 mb-1"></v-divider>
                <div class="d-flex justify-end align-center">
                  {{ `${jobCandidateData.companyJobGdpr.accepted ? $t('acceptedConsent') : $t('declinedConsent')}` }}
                  {{ formatDateToMonthShort(jobCandidateData.createdAt) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <v-subheader v-t="'contactConsent'" />
          <v-col cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-text>
                <div>{{ jobCandidateData.contactViaEmail.consent }}</div>
                <v-divider class="mt-1 mb-1"></v-divider>
                <div class="d-flex justify-end align-center">
                  {{ `${jobCandidateData.contactViaEmail.accepted ? $t('acceptedConsent') : $t('declinedConsent')}` }}
                  {{ formatDateToMonthShort(jobCandidateData.createdAt) }}
                </div>
              </v-card-text>
            </v-card>
          </v-col>
          <div v-if="jobCandidateData.additionalConsent && jobCandidateData.additionalConsent.length > 0">
            <v-subheader v-t="'additionalConsent'" />
            <v-col cols="12">
              <v-card
                v-for="(additionalConsent, index) in jobCandidateData.additionalConsent"
                :key="`Additional-consent--${index}`"
                outlined
                class="card--border-primary"
              >
                <v-card-text>
                  <div>{{ additionalConsent.consent }}</div>
                  <v-divider class="mt-1 mb-1"></v-divider>
                  <div class="d-flex justify-end align-center">
                    {{ `${additionalConsent.accepted ? $t('acceptedConsent') : $t('declinedConsent')}` }}
                    {{ formatDateToMonthShort(jobCandidateData.createdAt) }}
                  </div>
                </v-card-text>
              </v-card>
            </v-col>
          </div>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useState } from 'vuex-composition-helpers'

import { formatDateToMonthShort } from '@core/utils/filter'

export default {
  setup() {
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    return {
      jobCandidateData,
      formatDateToMonthShort,
    }
  },
}
</script>
