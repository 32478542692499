<template>
  <div class="user-tab-security">
    <v-card class="mb-7">
      <v-card-text>
        <v-row>
          <v-col v-for="answer in jobCandidateData.answers" :key="`Answer--${answer.id}`" cols="12">
            <v-card outlined class="card--border-primary">
              <v-card-title>
                {{ answer.question }}
              </v-card-title>
              <v-card-text>{{ emptyValueFormatter(answer.answer) }}</v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { useState } from 'vuex-composition-helpers'

import { emptyValueFormatter } from '@core/utils/filter'

export default {
  setup() {
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    return {
      jobCandidateData,
      emptyValueFormatter,
    }
  },
}
</script>
