<template>
  <v-row class="user-bio-panel">
    <v-col cols="12">
      <v-card class="pt-10">
        <v-card-title
          v-touch="{
            left: goToNext,
            right: goToPrevious,
          }"
          class="justify-center flex-column"
        >
          <div class="d-flex justify-space-around align-center w-full">
            <v-btn icon color="primary" :disabled="!canGoToPrevious || loading" @click="goToPrevious"
              ><v-icon>{{ icons.mdiChevronLeft }}</v-icon></v-btn
            >
            <v-avatar
              :color="jobCandidateData.avatar ? '' : 'primary'"
              :class="jobCandidateData.avatar ? '' : 'v-avatar-light-bg primary--text'"
              size="120"
              rounded
              class="mb-4"
            >
              <v-img
                v-if="jobCandidateData.avatar"
                :src="require(`@/assets/images/avatars/${jobCandidateData.avatar}`)"
              />
              <span v-else class="font-weight-semibold text-5xl">{{
                avatarText(`${jobCandidateData.candidate.user.firstName} ${jobCandidateData.candidate.user.lastName}`)
              }}</span>
            </v-avatar>
            <v-btn icon color="primary" :disabled="!canGoToNext || loading" @click="goToNext">
              <v-icon>{{ icons.mdiChevronRight }}</v-icon>
            </v-btn>
          </div>

          <span class="mb-2"
            >{{ jobCandidateData.candidate.user.firstName }} {{ jobCandidateData.candidate.user.lastName }}</span
          >
          <v-chip
            label
            small
            :color="resolveCandidateStageColor(jobCandidateData.stage).color"
            :class="`v-chip-light-bg ${
              resolveCandidateStageColor(jobCandidateData.stage).color
            }--text font-weight-semibold text-capitalize ma-1 mb-2`"
            >{{ $t(resolveCandidateStageColor(jobCandidateData.stage).text) }}</v-chip
          >
        </v-card-title>

        <v-card-text>
          <h2 v-t="'details'" class="text-xl font-weight-semibold mb-2" />
          <v-divider></v-divider>
          <v-list>
            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('firstName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ jobCandidateData.candidate.user.firstName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('lastName', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ jobCandidateData.candidate.user.lastName }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('status', { postfix: '' }) }}:</span>
              <v-chip
                label
                small
                :color="resolveCandidateStageColor(jobCandidateData.stage).color"
                :class="`v-chip-light-bg ${
                  resolveCandidateStageColor(jobCandidateData.stage).color
                }--text font-weight-semibold text-capitalize ma-1 mb-2`"
              >
                {{ $t(resolveCandidateStageColor(jobCandidateData.stage).text) }}
              </v-chip>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('gdprStatus') }}:</span>
              <v-chip
                label
                small
                :color="resolveGDPRStatusColor(jobCandidateData.gdprStatus).color"
                :class="`v-chip-light-bg ${
                  resolveGDPRStatusColor(jobCandidateData.gdprStatus).color
                }--text font-weight-semibold text-capitalize ma-1 mb-2`"
              >
                {{ $t(resolveGDPRStatusColor(jobCandidateData.gdprStatus).text) }}
              </v-chip>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('email', { postfix: '' }) }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.candidate.user.email) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('phoneNumber') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.candidate.user.phone) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('preferredCity') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.preferredCity) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('source') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.source) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('rejectReason') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.rejectReason) }}</span>
            </v-list-item>

            <v-list-item v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES)" dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('workingStartDay') }}:</span>
              <span class="text--secondary">{{ emptyValueFormatter(jobCandidateData.employeeWorkingStartDay) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('isInTalentPool') }}:</span>
              <v-icon v-if="jobCandidateData.isInTalentPool" color="success">{{ icons.mdiCheckCircleOutline }}</v-icon>
              <v-icon v-else color="warning">{{ icons.mdiCloseCircleOutline }}</v-icon>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdBy') }}:</span>
              <span class="text--secondary">{{ jobCandidateData.createdBy }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('createdAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(jobCandidateData.createdAt) }}</span>
            </v-list-item>

            <v-list-item dense class="px-0 my-n2">
              <span class="font-weight-medium me-2">{{ $t('updatedAt') }}:</span>
              <span class="text--secondary">{{ formatDateToMonthShort(jobCandidateData.updatedAt) }}</span>
            </v-list-item>
          </v-list>

          <v-row justify="center" class="my-2">
            <v-col
              v-if="jobCandidateData.offerStage === 'ongoing' && $can(ACTIONS.UPDATE, jobCandidateData)"
              cols="auto"
            >
              <v-btn small color="primary" @click="isBioDialogOpen = !isBioDialogOpen">
                <v-icon class="me-2" size="16">{{ icons.mdiPencilOutline }}</v-icon>
                <span v-t="'edit'" />
              </v-btn>
            </v-col>
          </v-row>
          <v-row justify="center" class="my-2">
            <v-col v-if="canMoveToNextStep && $can(ACTIONS.UPDATE, jobCandidateData)" cols="auto">
              <v-btn small color="primary" outlined @click="openMoveToNextStepDialog">
                <v-icon class="me-2" size="16">{{ icons.mdiArrowRight }}</v-icon>
                <span v-t="'moveToNextStep'" />
              </v-btn>
            </v-col>
            <v-col v-if="canHire && $can(ACTIONS.UPDATE, jobCandidateData)" cols="auto">
              <v-btn small color="success" @click="openHireDialog">
                <v-icon class="me-2" size="16">{{ icons.mdiBriefcaseOutline }}</v-icon>
                <span v-t="'hire'" />
              </v-btn>
            </v-col>
            <v-col v-if="canReject && $can(ACTIONS.UPDATE, jobCandidateData)" cols="auto">
              <v-btn small color="error" @click="openRejectDialog">
                <v-icon class="me-2" size="16">{{ icons.mdiClose }}</v-icon>
                <span v-t="'reject'" />
              </v-btn>
            </v-col>
          </v-row>

          <h2 class="text-xl font-weight-semibold mb-2 mt-4">Workflow - {{ jobCandidateData.workflow.title }}</h2>
          <v-divider></v-divider>
          <v-stepper
            vertical
            :value="candidateStepIndex"
            class="my-2 custom-header stepper-custom-preview"
            flat
            non-linear
          >
            <template v-for="(step, index) in jobCandidateData.workflow.steps">
              <v-stepper-step
                :key="`${index}-step`"
                :step="index"
                editable
                :color="getStepColor(index)"
                :complete="candidateStepIndex >= index"
                :edit-icon="icons.mdiCloseCircleOutline"
              >
                <div class="d-flex align-center">
                  <span class="text--primary text-4xl font-weight-bold me-3">{{ index + 1 }}</span>
                  <div class="d-flex flex-column">
                    <span class="text--primary text-sm font-weight-semibold">
                      {{ step.name }}
                      <v-chip v-if="canShowCurrentChip(index)" v-t="'current'" color="primary" outlined x-small />
                    </span>
                    <span class="text--secondary text-xs">{{ step.description }}</span>
                  </div>
                </div>
              </v-stepper-step>
              <v-stepper-content v-if="step.stepDayLimit !== null" :key="`${index}-step-content`" :step="index">
                <span class="font-weight-medium me-2">{{ $t('stepDayLimit', { postfix: ':' }) }}</span>
                <span class="text--secondary">{{ step.stepDayLimit }}</span>
              </v-stepper-content>
            </template>
          </v-stepper>
        </v-card-text>
      </v-card>

      <v-dialog
        v-if="canMoveToNextStep"
        v-model="moveToNextStepDialog"
        max-width="850px"
        persistent
        overlay-color="#7f7f7f"
        overlay-opacity="0.2"
      >
        <v-card class="pa-sm-10 pa-3">
          <v-card-title v-t="'moveToNextStep'" class="justify-center text-h5" />
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex align-center justify-center flex-column">
                <div v-t="'confirmationModalUndoneQuestion'" />
                <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="sendEmail"
                  :label="$t('notifyCandidateByEmail')"
                  hide-details="auto"
                  class="mt-0"
                  inset
                />
              </v-col>
              <v-col v-if="sendEmail" cols="12">
                <v-select
                  v-model="selectedEmail"
                  :label="$t('selectEmail')"
                  :placeholder="$t('selectEmail')"
                  :items="emailTemplates"
                  outlined
                  dense
                  hide-details="auto"
                  @change="setEmailTemplateData"
                ></v-select>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="7">
                <v-text-field
                  v-model="cc"
                  :label="$t('cc')"
                  :placeholder="$t('cc')"
                  outlined
                  dense
                  maxlength="100"
                  hide-details="auto"
                  :hint="$t('separateByComma')"
                ></v-text-field>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="5">
                <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                  <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                </v-switch>
              </v-col>
            </v-row>
            <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
              <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="selectedEmailTemplateSubject"
                    :label="$t('subject')"
                    :placeholder="$t('subject')"
                    outlined
                    dense
                    maxlength="100"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-select
                    v-model="selectedEmailTemplateLang"
                    outlined
                    dense
                    :items="[
                      {
                        text: $t('pl'),
                        value: 'pl',
                      },
                      {
                        text: $t('eng'),
                        value: 'eng',
                      },
                    ]"
                    :label="$t('language')"
                    :placeholder="$t('language')"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <summer-note
                    :config="{ height: 300 }"
                    hide-link-and-image-button
                    @ready="readySummerNote"
                  ></summer-note>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-3">
                <v-btn v-t="'discard'" outlined class="me-3" @click="closeMoveToNextStepDialog" />
                <v-btn color="primary" :loading="moveToNextStepButtonLoading" @click="moveSelectedCandidatesToNextStep">
                  {{ $t('moveToNextStep') }}
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="canHire"
        v-model="hireDialog"
        max-width="850px"
        persistent
        overlay-color="#7f7f7f"
        overlay-opacity="0.2"
      >
        <v-card class="pa-sm-10 pa-3">
          <v-card-title v-t="'hire'" class="justify-center text-h5" />
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex align-center justify-center flex-column">
                <div v-t="'confirmationModalUndoneQuestion'" />
                <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="sendEmail"
                  :label="$t('notifyCandidateByEmail')"
                  hide-details="auto"
                  class="mt-0"
                  inset
                />
              </v-col>
              <v-col v-if="sendEmail" cols="12">
                <v-select
                  v-model="selectedEmail"
                  :label="$t('selectEmail')"
                  :placeholder="$t('selectEmail')"
                  :items="emailTemplates"
                  outlined
                  dense
                  hide-details="auto"
                  @change="setEmailTemplateData"
                ></v-select>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="7">
                <v-text-field
                  v-model="cc"
                  :label="$t('cc')"
                  :placeholder="$t('cc')"
                  outlined
                  dense
                  maxlength="100"
                  hide-details="auto"
                  :hint="$t('separateByComma')"
                ></v-text-field>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="5">
                <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                  <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                </v-switch>
              </v-col>
              <v-col v-if="$can(ACTIONS.READ, PRIVATE.STAFF.EMPLOYEES) && sendEmail" cols="12">
                <v-menu
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                  :close-on-content-click="false"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="workingStartDay"
                      :label="$t('workingStartDay')"
                      readonly
                      outlined
                      dense
                      clearable
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="workingStartDay"
                    :first-day-of-week="1"
                    :locale="$i18n.locale"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
              <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="selectedEmailTemplateSubject"
                    :label="$t('subject')"
                    :placeholder="$t('subject')"
                    outlined
                    dense
                    maxlength="100"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-select
                    v-model="selectedEmailTemplateLang"
                    outlined
                    dense
                    :items="[
                      {
                        text: $t('pl'),
                        value: 'pl',
                      },
                      {
                        text: $t('eng'),
                        value: 'eng',
                      },
                    ]"
                    :label="$t('language')"
                    :placeholder="$t('language')"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <summer-note
                    :config="{ height: 300 }"
                    hide-link-and-image-button
                    @ready="readySummerNote"
                  ></summer-note>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-3">
                <v-btn v-t="'discard'" outlined class="me-3" @click="closeHireDialog" />
                <v-btn v-t="'hire'" color="primary" :loading="hireButtonLoading" @click="hireSelected" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog
        v-if="canReject"
        v-model="rejectDialog"
        max-width="850px"
        persistent
        overlay-color="#7f7f7f"
        overlay-opacity="0.2"
      >
        <v-card class="pa-sm-10 pa-3">
          <v-card-title v-t="'reject'" class="justify-center text-h5" />
          <v-card-text>
            <v-row>
              <v-col cols="12" class="d-flex align-center justify-center flex-column">
                <div v-t="'confirmationModalUndoneQuestion'" />
                <div v-if="!selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmail'" />
                <div v-else-if="selectedEmail && sendEmail" v-t="'confirmationModalDefaultEmailConditions'" />
              </v-col>
              <v-col cols="12">
                <v-switch
                  v-model="sendEmail"
                  :label="$t('notifyCandidateByEmail')"
                  hide-details="auto"
                  class="mt-0"
                  inset
                />
              </v-col>
              <v-col v-if="sendEmail" cols="12">
                <v-select
                  v-model="selectedEmail"
                  :label="$t('selectEmail')"
                  :placeholder="$t('selectEmail')"
                  :items="emailTemplates"
                  outlined
                  dense
                  hide-details="auto"
                  @change="setEmailTemplateData"
                ></v-select>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="7">
                <v-text-field
                  v-model="cc"
                  :label="$t('cc')"
                  :placeholder="$t('cc')"
                  outlined
                  dense
                  maxlength="100"
                  hide-details="auto"
                  :hint="$t('separateByComma')"
                ></v-text-field>
              </v-col>
              <v-col v-if="sendEmail" cols="12" sm="5">
                <v-switch v-model="sendCopyToRecruiter" class="mt-0" inset color="primary">
                  <template v-slot:label> {{ $t('sendMeCopy') }} </template>
                </v-switch>
              </v-col>
              <v-col v-if="sendEmail" cols="12">
                <v-select
                  v-model="selectedRejectReason"
                  :label="$t('selectRejectReason')"
                  :placeholder="$t('selectRejectReason')"
                  :items="rejectReasonChoices"
                  item-value="id"
                  item-text="rejectReason"
                  outlined
                  dense
                  hide-details="auto"
                ></v-select>
              </v-col>
            </v-row>
            <v-form v-if="selectedEmail && sendEmail" ref="emailForm">
              <v-row>
                <v-col cols="12" sm="7">
                  <v-text-field
                    v-model="selectedEmailTemplateSubject"
                    :label="$t('subject')"
                    :placeholder="$t('subject')"
                    outlined
                    dense
                    maxlength="100"
                    hide-details="auto"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" sm="5">
                  <v-select
                    v-model="selectedEmailTemplateLang"
                    outlined
                    dense
                    :items="[
                      {
                        text: $t('pl'),
                        value: 'pl',
                      },
                      {
                        text: $t('eng'),
                        value: 'eng',
                      },
                    ]"
                    :label="$t('language')"
                    :placeholder="$t('language')"
                    hide-details="auto"
                  ></v-select>
                </v-col>
                <v-col cols="12">
                  <summer-note
                    :config="{ height: 300 }"
                    hide-link-and-image-button
                    @ready="readySummerNote"
                  ></summer-note>
                </v-col>
              </v-row>
            </v-form>
            <v-row>
              <v-col cols="12" class="d-flex justify-center mt-3">
                <v-btn v-t="'discard'" outlined class="me-3" @click="closeRejectDialog" />
                <v-btn v-t="'reject'" color="primary" :loading="rejectButtonLoading" @click="rejectSelected" />
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-dialog>

      <candidate-bio-edit
        :is-bio-dialog-open.sync="isBioDialogOpen"
        :job-candidate-data.sync="jobCandidateData"
        @refetchCandidate="emit('refetchCandidate')"
      ></candidate-bio-edit>
    </v-col>
  </v-row>
</template>

<script>
import {
  mdiPencilOutline,
  mdiCheckCircleOutline,
  mdiCloseCircleOutline,
  mdiBriefcaseOutline,
  mdiClose,
  mdiArrowRight,
  mdiChevronLeft,
  mdiChevronRight,
} from '@mdi/js'
import { ref, computed, getCurrentInstance } from '@vue/composition-api'
import { useState, useActions } from 'vuex-composition-helpers'

import { avatarText, formatDateToMonthShort, emptyValueFormatter } from '@core/utils/filter'
import _ from 'lodash'
import SummerNote from '@/plugins/summer-note/SummerNote.vue'
import CandidateBioEdit from './CandidateBioEdit.vue'
import useCandidateList from '@/views/apps/ats/jobs/job-view/job-tab-candidates/useCandidateList'
import { EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE } from '@/plugins/summer-note/variables'
import router from '@/router'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

export default {
  components: {
    CandidateBioEdit,
    SummerNote,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { resolveCandidateStageColor, resolveGDPRStatusColor } = useCandidateList()

    const isBioDialogOpen = ref(false)

    const {
      getEmailTemplatesChoices,
      moveCandidatesToNextStep,
      hireCandidates,
      rejectCandidates,
      getRejectReasonsChoices,
    } = useActions('jobs', [
      'getEmailTemplatesChoices',
      'moveCandidatesToNextStep',
      'hireCandidates',
      'rejectCandidates',
      'getRejectReasonsChoices',
    ])
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    const candidateStepIndex = computed(() => {
      return jobCandidateData.value.workflow.steps.findIndex((s) => s.id === jobCandidateData.value.step.id)
    })

    const sendEmail = ref(true)
    const emailTemplates = ref([])
    const selectedEmail = ref(null)
    const selectedEmailTemplateHtml = ref('')
    const selectedEmailTemplateSubject = ref('')
    const selectedEmailTemplateLang = ref('')
    const cc = ref('')
    const sendCopyToRecruiter = ref(false)

    const vm = getCurrentInstance().proxy

    const editor = ref(null)
    const emailVariablesSummernote = ref([])
    emailVariablesSummernote.value = EMAIL_VARIABLES_WHEN_STATUS_CHANGED_SUMMERNOTE(vm)
    const readySummerNote = (initializedEditor) => {
      editor.value = initializedEditor
      editor.value.summernote('code', selectedEmailTemplateHtml.value)
      editor.value.$on('change', (content) => {
        selectedEmailTemplateHtml.value = content
      })
    }

    const setEmailTemplateData = () => {
      const emailTemplate = emailTemplates.value.find((et) => et.value === selectedEmail.value)
      selectedEmailTemplateHtml.value = emailTemplate?.bodyHtml
      selectedEmailTemplateSubject.value = emailTemplate?.subject
      selectedEmailTemplateLang.value = emailTemplate?.lang
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
    }

    const fetchEmailTemplatesData = async (actionType) => {
      emailTemplates.value = await getEmailTemplatesChoices({
        actionType,
      })
    }

    const resetEmailTemplateData = () => {
      sendEmail.value = true
      selectedEmail.value = null
      selectedEmailTemplateHtml.value = ''
      selectedEmailTemplateSubject.value = ''
      selectedEmailTemplateLang.value = ''
      cc.value = ''
      sendCopyToRecruiter.value = false
      editor.value?.summernote('code', selectedEmailTemplateHtml.value)
      emailTemplates.value = []
    }

    const moveToNextStepDialog = ref(false)
    const moveToNextStepButtonLoading = ref(false)
    const canMoveToNextStep = computed(() => {
      return (
        jobCandidateData.value.step.id !== jobCandidateData.value.workflow.steps.slice(-1).pop().id &&
        (jobCandidateData.value.stage === 'in_progress' || jobCandidateData.value.stage === 'new') &&
        jobCandidateData.value.offerStage === 'ongoing' &&
        jobCandidateData.value.gdprStatus !== 'anonymized'
      )
    })
    const openMoveToNextStepDialog = async () => {
      if (!canMoveToNextStep.value) return
      await fetchEmailTemplatesData('in_progress')
      moveToNextStepDialog.value = true
    }
    const closeMoveToNextStepDialog = () => {
      moveToNextStepDialog.value = false
      moveToNextStepButtonLoading.value = false
      resetEmailTemplateData()
    }
    const moveSelectedCandidatesToNextStep = async () => {
      moveToNextStepButtonLoading.value = true
      await moveCandidatesToNextStep({
        jobId: router.currentRoute.params.jobId,
        candidatesOfferDetail: [jobCandidateData.value.id],
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        sendEmail: sendEmail.value,
      })
      emit('refetchCandidate')
      closeMoveToNextStepDialog()
    }

    const hireDialog = ref(false)
    const hireButtonLoading = ref(false)
    const workingStartDay = ref(new Date().toISOString().substr(0, 10))
    const canHire = computed(() => {
      return (
        jobCandidateData.value.step.id === jobCandidateData.value.workflow.steps.slice(-1).pop().id &&
        (jobCandidateData.value.stage === 'in_progress' || jobCandidateData.value.stage === 'new') &&
        jobCandidateData.value.offerStage === 'ongoing' &&
        jobCandidateData.value.gdprStatus !== 'anonymized'
      )
    })
    const openHireDialog = async () => {
      if (!canHire.value) return
      await fetchEmailTemplatesData('hired')
      hireDialog.value = true
    }
    const closeHireDialog = () => {
      hireDialog.value = false
      hireButtonLoading.value = false
      resetEmailTemplateData()
    }
    const hireSelected = async () => {
      hireButtonLoading.value = true
      await hireCandidates({
        jobId: router.currentRoute.params.jobId,
        candidatesOfferDetail: [jobCandidateData.value.id],
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        workingStartDay: workingStartDay.value,
        sendEmail: sendEmail.value,
      })
      emit('refetchCandidate')
      closeHireDialog()
    }

    const rejectDialog = ref(false)
    const selectedRejectReason = ref(null)
    const rejectReasonChoices = ref([])

    const rejectButtonLoading = ref(false)
    const canReject = computed(() => {
      return (
        (jobCandidateData.value.stage === 'in_progress' || jobCandidateData.value.stage === 'new') &&
        jobCandidateData.value.offerStage === 'ongoing' &&
        jobCandidateData.value.gdprStatus !== 'anonymized'
      )
    })
    const openRejectDialog = async () => {
      if (!canReject.value) return
      await fetchEmailTemplatesData('rejected')
      rejectReasonChoices.value = await getRejectReasonsChoices()
      rejectDialog.value = true
    }

    const closeRejectDialog = () => {
      rejectDialog.value = false
      rejectButtonLoading.value = false
      resetEmailTemplateData()
    }
    const rejectSelected = async () => {
      rejectButtonLoading.value = true
      await rejectCandidates({
        jobId: router.currentRoute.params.jobId,
        candidatesOfferDetail: [jobCandidateData.value.id],
        bodyHtml: selectedEmailTemplateHtml.value,
        subject: selectedEmailTemplateSubject.value,
        lang: selectedEmailTemplateLang.value,
        cc: cc.value,
        sendCopyToRecruiter: sendCopyToRecruiter.value,
        rejectReason: selectedRejectReason.value,
        sendEmail: sendEmail.value,
      })
      emit('refetchCandidate')
      closeRejectDialog()
    }

    const getStepColor = (index) => {
      if (index === candidateStepIndex.value) {
        if (jobCandidateData.value.stage === 'rejected') return 'error'

        if (jobCandidateData.value.stage === 'hired') return 'success'
      }

      return 'primary'
    }

    const canShowCurrentChip = (index) => {
      return index === candidateStepIndex.value && ['new', 'in_progress'].includes(jobCandidateData.value.stage)
    }

    const canGoToNext = computed(() => {
      const lastElement = _.last(jobCandidateData.value.offerCandidateIds)

      return lastElement && lastElement !== jobCandidateData.value.candidate?.id
    })

    const goToNext = async () => {
      if (!canGoToNext.value || props.loading.value) return
      const currentIdIndex = _.indexOf(jobCandidateData.value.offerCandidateIds, jobCandidateData.value.candidate.id)
      await router.push({
        name: 'apps-ats-candidate-view',
        params: {
          jobId: jobCandidateData.value.offerId,
          candidateId: jobCandidateData.value.offerCandidateIds[currentIdIndex + 1],
        },
      })
    }

    const canGoToPrevious = computed(() => {
      const firsElement = _.first(jobCandidateData.value.offerCandidateIds)

      return firsElement && firsElement !== jobCandidateData.value.candidate?.id
    })

    const goToPrevious = async () => {
      if (!canGoToPrevious.value || props.loading.value) return
      const currentIdIndex = _.indexOf(jobCandidateData.value.offerCandidateIds, jobCandidateData.value.candidate.id)
      await router.push({
        name: 'apps-ats-candidate-view',
        params: {
          jobId: jobCandidateData.value.offerId,
          candidateId: jobCandidateData.value.offerCandidateIds[currentIdIndex - 1],
        },
      })
    }

    return {
      emit,

      formatDateToMonthShort,
      emptyValueFormatter,
      resolveCandidateStageColor,
      resolveGDPRStatusColor,
      avatarText,

      getStepColor,
      canShowCurrentChip,

      candidateStepIndex,

      jobCandidateData,
      isBioDialogOpen,

      sendEmail,
      emailTemplates,
      selectedEmail,
      selectedEmailTemplateHtml,
      selectedEmailTemplateSubject,
      selectedEmailTemplateLang,
      cc,
      sendCopyToRecruiter,

      setEmailTemplateData,
      fetchEmailTemplatesData,

      editor,
      emailVariablesSummernote,
      readySummerNote,

      moveToNextStepDialog,
      moveToNextStepButtonLoading,
      openMoveToNextStepDialog,
      closeMoveToNextStepDialog,
      moveSelectedCandidatesToNextStep,
      canMoveToNextStep,
      hireDialog,
      hireButtonLoading,
      workingStartDay,
      openHireDialog,
      closeHireDialog,
      hireSelected,
      canHire,
      rejectDialog,
      rejectButtonLoading,
      openRejectDialog,
      closeRejectDialog,
      rejectSelected,
      canReject,
      selectedRejectReason,
      rejectReasonChoices,

      canGoToNext,
      goToNext,
      canGoToPrevious,
      goToPrevious,

      icons: {
        mdiPencilOutline,
        mdiCheckCircleOutline,
        mdiCloseCircleOutline,
        mdiBriefcaseOutline,
        mdiClose,
        mdiArrowRight,
        mdiChevronLeft,
        mdiChevronRight,
      },
      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
