<template>
  <div class="chat-log pa-5">
    <div
      v-for="(internalNoteGroup, index) in formattedInternalNotes"
      :key="internalNoteGroup.senderId + String(index)"
      class="chat-group d-flex align-start"
      :class="[
        { 'flex-row-reverse': internalNoteGroup.sender.id === recruiter.id },
        { 'mb-8': formattedInternalNotes.length - 1 !== index },
      ]"
    >
      <div class="chat-avatar" :class="internalNoteGroup.sender.id === recruiter.id ? 'ms-4' : 'me-4'">
        <v-avatar size="38" color="primary" class="v-avatar-light-bg primary--text">
          <span class="font-weight-medium">{{ avatarText(internalNoteGroup.sender.name) }}</span>
        </v-avatar>
      </div>
      <div
        class="chat-body d-inline-flex flex-column"
        :class="internalNoteGroup.sender.id !== recruiter.id ? 'align-end' : 'align-start'"
      >
        <v-tooltip
          v-for="(internalNote, internalNoteIndex) in internalNoteGroup.internalNotes"
          :key="internalNote.id"
          :right="internalNoteGroup.sender.id !== recruiter.id"
          :left="internalNoteGroup.sender.id === recruiter.id"
          transition="scroll-x-transition"
          color="secondary"
          open-delay="150"
        >
          <template #activator="{ on, attrs }">
            <div
              class="d-flex align-center"
              :class="[
                { 'flex-row-reverse': internalNoteGroup.sender.id === recruiter.id },
                internalNoteGroup.internalNotes.length - 1 !== internalNoteIndex ? 'mb-2' : 'mb-1',
                internalNoteGroup.sender.id === recruiter.id ? 'align-self-end' : 'align-self-start',
              ]"
            >
              <p
                v-bind="attrs"
                class="chat-content py-3 px-4 elevation-1 mb-0"
                :class="[
                  internalNoteGroup.sender.id !== recruiter.id ? 'bg-card chat-left' : 'primary white--text chat-right',
                  internalNoteGroup.sender.id === recruiter.id ? 'align-self-end' : 'align-self-start',
                ]"
                v-on="on"
              >
                {{ internalNote.note }}
              </p>
              <v-btn
                v-if="$can(ACTIONS.DELETE, PRIVATE.INTERNAL_NOTE)"
                icon
                class="align-self-center"
                @click="$emit('delete-internal-note', internalNote.id)"
              >
                <v-icon size="20" color="error">
                  {{ icons.mdiTrashCanOutline }}
                </v-icon>
              </v-btn>
            </div>
          </template>
          <span class="text-xs">
            {{ internalNote.createdBy }}, {{ formatDateToMonthShort(internalNote.createdAt) }}</span
          >
        </v-tooltip>
      </div>
    </div>
  </div>
</template>

<script>
import { mdiTrashCanOutline } from '@mdi/js'
import { computed } from '@vue/composition-api'
import { formatDateToMonthShort, avatarText } from '@core/utils/filter'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'

// TODO: Create Internal Note Log common component
export default {
  props: {
    recruiter: {
      type: Object,
      required: true,
    },
    internalNotesData: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const formattedInternalNotes = computed(() => {
      if (props.internalNotesData.length === 0) {
        return []
      }

      const internalNotesByGroup = []

      let group = {
        sender: {
          id: props.internalNotesData[0].createdById,
          name: props.internalNotesData[0].createdBy,
        },
        internalNotes: [],
      }

      props.internalNotesData.forEach((internalNote, index) => {
        if (internalNote.createdById === group.sender.id) {
          group.internalNotes.push(internalNote)
        } else {
          internalNotesByGroup.push(group)
          group = {
            sender: {
              id: internalNote.createdById,
              name: internalNote.createdBy,
            },
            internalNotes: [internalNote],
          }
        }

        if (index === props.internalNotesData.length - 1) internalNotesByGroup.push(group)
      })

      return internalNotesByGroup
    })

    return {
      formattedInternalNotes,

      // Filter
      formatDateToMonthShort,
      avatarText,

      icons: {
        mdiTrashCanOutline,
      },

      ACTIONS,
      PRIVATE,
    }
  },
}
</script>
<style lang="scss">
@import '~@core/preset/preset/apps/chat.scss';
</style>
