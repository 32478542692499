<template>
  <v-dialog v-model="isBioDialogOpen" max-width="650px" @click:outside="$emit('update:is-bio-dialog-open', false)">
    <v-card class="user-edit-info pa-sm-10 pa-3">
      <v-card-title v-t="'edit'" class="justify-center text-h5" />
      <v-card-text class="mt-5">
        <v-form ref="bioEditForm" v-model="valid" class="multi-col-validation" @submit.prevent="handleFormSubmit">
          <v-row>
            <v-col cols="6">
              <v-text-field
                v-model="jobCandidateDataLocal.firstName"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('firstNameRequired')"
                :placeholder="$t('firstNameRequired')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="jobCandidateDataLocal.lastName"
                outlined
                dense
                maxlength="100"
                hide-details="auto"
                :label="$t('lastNameRequired')"
                :placeholder="$t('lastNameRequired')"
                :rules="[validators.required]"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="jobCandidateDataLocal.phone"
                outlined
                dense
                maxlength="31"
                hide-details="auto"
                :label="$t('phoneNumber')"
                :placeholder="$t('phoneNumber')"
              ></v-text-field>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="jobCandidateDataLocal.preferredCity"
                outlined
                dense
                maxlength="128"
                hide-details="auto"
                :label="$t('preferredCity')"
                :placeholder="$t('preferredCity')"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="12" class="d-flex justify-center mt-3">
              <v-btn
                v-t="'discard'"
                outlined
                color="secondary"
                class="me-3"
                @click.prevent="$emit('update:is-bio-dialog-open', false)"
              />
              <v-btn color="primary" type="submit" :loading="loading" :disabled="!valid || !areDataChanged">
                {{ $t('save') }}
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, watch, computed } from '@vue/composition-api'
import { useActions } from 'vuex-composition-helpers'
import { required } from '@core/utils/validation'
import _ from 'lodash'

export default {
  props: {
    isBioDialogOpen: {
      type: Boolean,
      required: true,
    },
    jobCandidateData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const valid = ref(true)
    const loading = ref(false)

    const bioEditForm = ref(null)

    const jobCandidateDataLocal = ref({})
    jobCandidateDataLocal.value = _.cloneDeep({
      firstName: props.jobCandidateData.candidate.user.firstName,
      lastName: props.jobCandidateData.candidate.user.lastName,
      phone: props.jobCandidateData.candidate.user.phone,
      preferredCity: props.jobCandidateData.preferredCity,
    })

    const { updateCandidateInOffer } = useActions('jobs', ['updateCandidateInOffer'])

    const handleFormSubmit = async () => {
      const isFormValid = bioEditForm.value.validate()
      if (!isFormValid) return

      loading.value = true
      const { success } = await updateCandidateInOffer({
        candidateDetailId: props.jobCandidateData.id,
        payload: {
          user: {
            firstName: jobCandidateDataLocal.value.firstName,
            lastName: jobCandidateDataLocal.value.lastName,
            phone: jobCandidateDataLocal.value.phone,
          },
          preferredCity: jobCandidateDataLocal.value.preferredCity,
        },
      })
      loading.value = false

      if (success) {
        emit('update:is-bio-dialog-open', false)
        emit('refetchCandidate')
      }
    }

    watch(
      () => props.isBioDialogOpen,
      () => {
        jobCandidateDataLocal.value = _.cloneDeep({
          firstName: props.jobCandidateData.candidate.user.firstName,
          lastName: props.jobCandidateData.candidate.user.lastName,
          phone: props.jobCandidateData.candidate.user.phone,
          preferredCity: props.jobCandidateData.preferredCity,
        })
      },
    )

    const areDataChanged = computed(() => {
      return (
        jobCandidateDataLocal.value.firstName !== props.jobCandidateData.candidate.user.firstName ||
        jobCandidateDataLocal.value.lastName !== props.jobCandidateData.candidate.user.lastName ||
        jobCandidateDataLocal.value.phone !== props.jobCandidateData.candidate.user.phone ||
        jobCandidateDataLocal.value.preferredCity !== props.jobCandidateData.preferredCity
      )
    })

    return {
      handleFormSubmit,

      areDataChanged,

      valid,
      loading,
      bioEditForm,
      jobCandidateDataLocal,

      validators: {
        required,
      },
    }
  },
}
</script>
