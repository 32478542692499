<template>
  <div class="user-tab-security">
    <file-table
      :loading="loading"
      :files="localFiles"
      groups-disabled
      handle-reminders
      :handle-document-generation="$can(ACTIONS.UPDATE, jobCandidateData)"
      :document-generator-data="{
        module: MODULE_CANDIDATES,
        obj: jobCandidateData,
        target: `${jobCandidateData.candidate.user.firstName} ${jobCandidateData.candidate.user.lastName}`,
      }"
      :disable-upload="!$can(ACTIONS.UPDATE, jobCandidateData)"
      :upload-file-callback="uploadFileCandidateInOffer"
      disable-delete
      :disable-update="!$can(ACTIONS.UPDATE, jobCandidateData)"
      :update-file-callback="updateFileCandidateInOffer"
      :file-target-object="{ candidateDetailId: jobCandidateData.id }"
      :reminder-target-object="{ candidateOfferDetail: jobCandidateData.id }"
      @refetch-data="refetchData"
    />
  </div>
</template>

<script>
import { ref } from '@vue/composition-api'
import { useActions, useState } from 'vuex-composition-helpers'

import _ from 'lodash'
import router from '@/router'
import documentTemplateUtils from '@/utils/templates/document/documentTemplateUtils'
import { ACTIONS, PRIVATE } from '@/plugins/acl/const'
import FileTable from '@/components/files-table/FileTable.vue'

export default {
  components: { FileTable },
  setup() {
    const { updateFileCandidateInOffer, getJobCandidateDetails, uploadFileCandidateInOffer } = useActions('jobs', [
      'updateFileCandidateInOffer',
      'getJobCandidateDetails',
      'uploadFileCandidateInOffer',
    ])
    const { jobCandidateData } = useState('jobs', ['jobCandidateData'])

    const { MODULE_CANDIDATES } = documentTemplateUtils()

    const localFiles = ref([])
    const loading = ref(false)

    localFiles.value = _.cloneDeep(jobCandidateData.value.files)

    const refetchData = async () => {
      loading.value = true
      await getJobCandidateDetails({
        jobId: router.currentRoute.params.jobId,
        candidateId: router.currentRoute.params.candidateId,
      })
      localFiles.value = _.cloneDeep(jobCandidateData.value.files)
      loading.value = false
    }

    return {
      refetchData,
      uploadFileCandidateInOffer,
      updateFileCandidateInOffer,

      jobCandidateData,
      localFiles,
      loading,

      MODULE_CANDIDATES,
      PRIVATE,
      ACTIONS,
    }
  },
}
</script>
<style scoped lang="scss"></style>
