var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"chat-log pa-5"},_vm._l((_vm.formattedInternalNotes),function(internalNoteGroup,index){return _c('div',{key:internalNoteGroup.senderId + String(index),staticClass:"chat-group d-flex align-start",class:[
      { 'flex-row-reverse': internalNoteGroup.sender.id === _vm.recruiter.id },
      { 'mb-8': _vm.formattedInternalNotes.length - 1 !== index } ]},[_c('div',{staticClass:"chat-avatar",class:internalNoteGroup.sender.id === _vm.recruiter.id ? 'ms-4' : 'me-4'},[_c('v-avatar',{staticClass:"v-avatar-light-bg primary--text",attrs:{"size":"38","color":"primary"}},[_c('span',{staticClass:"font-weight-medium"},[_vm._v(_vm._s(_vm.avatarText(internalNoteGroup.sender.name)))])])],1),_c('div',{staticClass:"chat-body d-inline-flex flex-column",class:internalNoteGroup.sender.id !== _vm.recruiter.id ? 'align-end' : 'align-start'},_vm._l((internalNoteGroup.internalNotes),function(internalNote,internalNoteIndex){return _c('v-tooltip',{key:internalNote.id,attrs:{"right":internalNoteGroup.sender.id !== _vm.recruiter.id,"left":internalNoteGroup.sender.id === _vm.recruiter.id,"transition":"scroll-x-transition","color":"secondary","open-delay":"150"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('div',{staticClass:"d-flex align-center",class:[
              { 'flex-row-reverse': internalNoteGroup.sender.id === _vm.recruiter.id },
              internalNoteGroup.internalNotes.length - 1 !== internalNoteIndex ? 'mb-2' : 'mb-1',
              internalNoteGroup.sender.id === _vm.recruiter.id ? 'align-self-end' : 'align-self-start' ]},[_c('p',_vm._g(_vm._b({staticClass:"chat-content py-3 px-4 elevation-1 mb-0",class:[
                internalNoteGroup.sender.id !== _vm.recruiter.id ? 'bg-card chat-left' : 'primary white--text chat-right',
                internalNoteGroup.sender.id === _vm.recruiter.id ? 'align-self-end' : 'align-self-start' ]},'p',attrs,false),on),[_vm._v(" "+_vm._s(internalNote.note)+" ")]),(_vm.$can(_vm.ACTIONS.DELETE, _vm.PRIVATE.INTERNAL_NOTE))?_c('v-btn',{staticClass:"align-self-center",attrs:{"icon":""},on:{"click":function($event){return _vm.$emit('delete-internal-note', internalNote.id)}}},[_c('v-icon',{attrs:{"size":"20","color":"error"}},[_vm._v(" "+_vm._s(_vm.icons.mdiTrashCanOutline)+" ")])],1):_vm._e()],1)]}}],null,true)},[_c('span',{staticClass:"text-xs"},[_vm._v(" "+_vm._s(internalNote.createdBy)+", "+_vm._s(_vm.formatDateToMonthShort(internalNote.createdAt)))])])}),1)])}),0)}
var staticRenderFns = []

export { render, staticRenderFns }